import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import ContactUsBox from "./ContactUsBox";
import "./ContactUs.scss";
import Map from "./Map";
import { MailFilled, MailOutlined, PhoneFilled } from "@ant-design/icons";
import ContactAcountDetails from "./ContactAcountDetails";
import ContactService from "./ContactService";
import ContactUsForm from "./ContactUsForm";
const ContactUs = () => {
  return (
    <>
      <div className="pr-[25px] milgg:pr-[20%] milgg:pl-[20%] pl-[25px] miilg:pr-[20px] miilg:pl-[20px] bg-[#fff] mb-14">
        <ShopLayoutTitle title={"Home"} PathTo={"/"} Page={"Contact Us"} />
        <div className="flex justify-center flex-col items-center gap-[20px]">
          <h2 className="text-[35px] maxlm:text-[20px] font-bold">
            Keep in Touch with Us
          </h2>
          <p className="max-w-[650px] maxlm:text-[14px] leading-[26px] text-center text-[18px]">
            Contact us for all your questions and opinions, or you can solve
            your problems in a shorter time with our contact offices.
          </p>
        </div>
        <div className="flex pr-[50px] maxlg:pl-[10px] hiilg:flex-wrap hiilg:gap-[20px]  maxlg:pr-[10px] w-[100%] pl-[50px] mt-11 justify-between">
          <ContactUsBox
            title={"Email Address"}
            discreption={"sales@konozelectric.com"}
            icon={<MailFilled />}
          />
          <ContactUsBox
            title={"Phone Number"}
            CustomStyle={"mb-5"}
            discreption={"00966500350480"}
            icon={<PhoneFilled />}
          />
          <ContactUsBox
            title={"Main Office"}
            discreption={"Kingdom of Saudi Arabia Riyadh 14215"}
            icon={<MailOutlined />}
          />
        </div>
        <div className={`flex gap-[70px] miillg:gap-5 miillg:flex-col mt-24`}>
          <Map />
          <div
            className={`flex flex-col 
						miillg:flex-row gap-[15px] miillg:gap-5 
						miillg:w-[100%] w-[30%] miillg:items-center
						
						xllg:flex-wrap
						`}
          >
            <ContactAcountDetails />
            <ContactService />
          </div>
        </div>
        <div>
          <ContactUsForm />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
