import { Dropdown, DropdownProps, MenuProps, Space } from "antd";
import { DownOutlined, MenuOutlined, SettingOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useGetAllCategoriesQuery } from "../../../redux/Service/api";

const AllcatogeryDropdown = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
  } = useGetAllCategoriesQuery(undefined);

  const allCategories = categoriesMapper(allCategoriessData?.data ?? []);

  function categoriesMapper(categories) {
    return categories.map((category) => ({
      label: (
        <p
          className={`${
            category.categories.length > 0 ? "font-bold" : ""
          } min-w-40`}
          onClick={() => {
            console.log(category);
            if (!category.items_count && category.categories.length > 0) {
              navigate(`/shop/catogery/${category.id}`);
            } else if (
              category.items_count &&
              category.categories.length === 0
            ) {
              navigate(`/shop/catogery/Details/${category.id}`);
            }
          }}
        >
          {category.name}
        </p>
      ),
      key: category.id,
      ...(category.categories?.length > 0 && {
        children: categoriesMapper(category.categories),
      }),
    }));
  }

  return (
    <div className="miillg:hidden">
      <Dropdown
        menu={{
          items: allCategories,
        }}
      >
        <span
          onClick={(e) => e.preventDefault()}
          className={`bg-[#009e97] pt-3 pb-3 cursor-pointer text-[#fff] rounded-[5px] flex justify-between items-center w-[240px] pr-3 pl-3`}
        >
          <Space>
            <MenuOutlined className="text-[13px]" />
            <span className="text-[15px] font-bold">All Categories</span>
          </Space>
          <DownOutlined className="text-[13px]" />
        </span>
      </Dropdown>
    </div>
  );
};

export default AllcatogeryDropdown;
