import img from "../../assets/image/aboutUS.png";
import Logo from "../../assets/svg/FooterLogo.svg";
import { SafetyOutlined } from "@ant-design/icons";
const AboutLanding = () => {
	return (
		<div className={`flex flex-wrap gap-[20px] justify-between miillg:gap-[60px] mb-11`}>
		<div className="w-[36%] miillg:w-[100%]">
			<div className={`w-[100px] maxlm:w-[70px] mb-7`}>
				<img src={img} alt="" />
			</div>
			<div className={`w-[270px] maxlm:w-[230px] mb-7`}>
				<img src={Logo} alt="" />
			</div>
			<h2 className="text-[30px] maxlm:text-[23px] text-[#009E97] font-bold mb-7">
				Konoz Agility And Compliance
			</h2>
			<p className="text-[#777777] maxlm:text-[14px] leading-[31px]">
				With over 20 years of experience, we continue our mission of
				constantly striving to make the best producers even better. We will
				continue working hard to develop technologies and services that
				consistently meet the ever-changing needs
			</p>
		</div>
		<div className="w-[60%] flex flex-wrap miillg:w-[100%] miillg:gap-[50px]">
			<div className="flex w-[50%] maxlm:w-[100%] items-start gap-2">
				<SafetyOutlined className="text-[#009E97] text-[40px]" />
				<div className="flex flex-col gap-[10px]">
					<h3 className="text-[20px] font-bold text-[#0B3954] maxlm:text-[17px]">
						Verified professionals
					</h3>
					<p className="text-[#777777] text-[17px]">
						One of the largest independent suppliers of tools
					</p>
				</div>
			</div>
			<div className="flex w-[50%] items-start gap-2 maxlm:w-[100%]">
				<SafetyOutlined className="text-[#009E97] text-[40px]" />
				<div className="flex flex-col gap-[10px]">
					<h3 className="text-[20px] font-bold text-[#0B3954] maxlm:text-[17px]">
						Verified professionals
					</h3>
					<p className="text-[#777777] text-[17px]">
						One of the largest independent suppliers of tools
					</p>
				</div>{" "}
			</div>
			<div className="flex w-[50%] items-start gap-2 maxlm:w-[100%]">
				<SafetyOutlined className="text-[#009E97] text-[40px]" />
				<div className="flex flex-col gap-[10px]">
					<h3 className="text-[20px] font-bold text-[#0B3954] maxlm:text-[17px]">
						Verified professionals
					</h3>
					<p className="text-[#777777] text-[17px]">
						One of the largest independent suppliers of tools
					</p>
				</div>{" "}
			</div>
			<div className="flex w-[50%] items-start gap-2 maxlm:w-[100%]">
				<SafetyOutlined className="text-[#009E97] text-[40px]" />
				<div className="flex flex-col gap-[10px]">
					<h3 className="text-[20px] font-bold text-[#0B3954] maxlm:text-[17px]">
						Verified professionals
					</h3>
					<p className="text-[#777777] text-[17px]">
						One of the largest independent suppliers of tools
					</p>
				</div>{" "}
			</div>
		</div>
		<div></div>
	</div>
	)
}

export default AboutLanding