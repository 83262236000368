import TreadMarkSlider from "../../components/common/TreadMarkslider/TreadMarkSlider";
import ElementorContainer from "./ElementorContainer/ElementorContainer";
import ServicesSectionBox from "./ServicesSection/ServicesSectionBox";
import { ReactComponent as FreeDelivery } from "../../assets/svg/FreeDelivery.svg";
import { ReactComponent as Payment } from "../../assets/svg/Payment.svg";
import { ReactComponent as Support } from "../../assets/svg/Support.svg";
import HelpSection from "./HelpSection/HelpSection";
import HelpCatogery from "./HelpCatogery/HelpCatogery";
import PopulatedSection from "./populatedSection/PopulatedSection";
import AgricultureSection from "./AgricultureSection/AgricultureSection";
import CommercialSection from "./CommercialSection/CommercialSection";
import GrabDealSection from "./GrabDealSection/GrabDealSection";
import RecommendedSection from "./RecommendedSection/RecommendedSection";
import { Link } from "react-router-dom";
import { StarFilled } from "@ant-design/icons";
import HomeCatogery from "./HomeCatogery";

const Home = () => {
  return (
    <div className="pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] miilg:pr-[20px] miilg:pl-[20px] bg-[#f2fbfc]">
      <div className={`w-full h-full cursor-pointer`}>
        {/* <TreadMarkSlider Duration={2000} /> */}
        
        {/* <TreadMarkSlider Duration={2100} /> */}
        <ElementorContainer />
        <div className={`flex justify-between mt-11 mb-11 flex-wrap gap-y-10`}>
          <ServicesSectionBox
            title={`Services`}
            icon={<FreeDelivery />}
            describtion={`Over 1000 SAR in Riyadh`}
          />
          <ServicesSectionBox
            title={`Online payment`}
            icon={<Payment />}
            describtion={`All credit cards & Paypal acceted`}
          />
          <ServicesSectionBox
            title={`Backorder support`}
            icon={<Support />}
            describtion={`Wide selection of high-quality brands`}
          />
        </div>
        <HelpSection />
        <HomeCatogery/>
        <HelpCatogery />
        <PopulatedSection />
        <AgricultureSection />
        {/* <CommercialSection /> */}
        {/* <GrabDealSection /> */}
        {/* <RecommendedSection /> */}

        <div className="bg-[#ffff] pt-20 pb-20 text-center ">
          <Link to={""} className="border-[1px] solid border-[#00b67a] p-4">
            <span className="pr-2">Review us on</span>
            <StarFilled className="pr-2 text-[18px] text-[#00b67a] fill-[#00b67a]" />
            <span className="font-bold capitalize text-[14px]">Trustpilot</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
