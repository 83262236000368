import React from "react";
import helpImage from "../../../assets/image/banner.png";
import "./HelpSection.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
const HelpSection = () => {
  return (
    <div className="h-[280px] border-[1.5px] border-[#ccc] rounded-[10px] relative overflow-hidden mb-11 Help">
      <div className="overlay absolute top-0 right-0 bg-black z-40 h-full w-full opacity-[0] "></div>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-40 flex flex-col items-center gap-5 p-8 maxlm:p-3 w-full Help_text">
        <InfoCircleOutlined className={`text-[50px] maxlm:text-[35px]`} />
        <p className="text-[23px] font-bold maxlm:text-[18px]">Need more help !</p>
        <p className="text-[14px] maxlm:text-center">
          {" "}
          "Learn with ease!" Explore our comprehensive library of tutorial
          videos that explain everything you need to know about our platform
        </p>
        <p
          className={`pt-2 pb-2 pr-3 pl-3 font-bold text-[14px] border-[2px] maxlm:pr-2 maxlm:pl-2 border-[#000] solid`}
        >
          Check out the instructional videos.
        </p>
      </div>
      <p className="bg-[#00A9A5] text-[#fff] text-[14px] font-bold pt-[2.5px] pb-[2.5px] pr-[50px] pl-[50px] absolute top-[0px] translate-y-[30px] translate-x-[30px] right-[-10px] rotate-[40deg] z-50">
        متوفر باللغة العربية
      </p>
      <div className="h-full w-ful">
        <img className="w-full h-full rounded-[10px]" src={helpImage} alt="" />
      </div>
    </div>
  );
};

export default HelpSection;
