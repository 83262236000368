import AgricultureLInks from "./AgricultureLInks";
import AgricultureImage from "./AgricultureImage";
import { useGetAllCategoriesQuery } from "../../../redux/Service/api";
const AgricultureSection = () => {
  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
  } = useGetAllCategoriesQuery(undefined);

  const allCategories = allCategoriessData?.data ?? [];

  return (
    <div className="flex flex-col gap-6">
      {allCategories?.map((category) => (
        <div className="flex flex-nowrap hilg:flex-col justify-between bg-[#ffff]">
          <AgricultureImage category={category} />
          <div className="flex hilg:mt-5 hilg:w-[100%] w-[60%] maxlm:w-[100%] justify-start maxlm:flex-wrap  maxlm:justify-center maxlm:pl-10">
            {category.categories && category.categories.length > 0 && (
              <AgricultureLInks subCategoies={category.categories} />
            )}
            {/* <AgricultureLInks /> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AgricultureSection;
