import React from "react";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Input, Space } from "antd";
import { Link } from "react-router-dom";

const items: MenuProps["items"] = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const DropdownWidthSearch: React.FC = () => (
  <div className="h-[50px] flex items-center DropdownWidthSearch border-[1.5px] solid rounded-[5px] miillg:hidden">
    {/* <Dropdown menu={{ items }} trigger={["click"]} className="h-full">
      <Link
        to={""}
        onClick={(e) => e.preventDefault()}
        className={`pt-3 bg-[#fff] text-[#000] pb-3 pr-2 pl-2 font-bold text-[15px]`}
      >
        <Space>
          All Categories
          <DownOutlined className="text-[13px]" />
        </Space>
      </Link>
    </Dropdown> */}
    <div className="relative">
      <SearchOutlined className="right-[13px] absolute top-[25%] text-[23px]"/>
      <input
        type="text"
        className="p-3 pr-[43px] outline-none w-[310px]"
        placeholder="What are you looking for?"
      />
    </div>
  </div>
);

export default DropdownWidthSearch;
