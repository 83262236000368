import { Link } from "react-router-dom";
import Logo from "../../assets/image/logo-e.png";
import AllcatogeryDropdown from "./AllcatogeryDropdown/AllcatogeryDropdown";
import DropdownWidthSearch from "./DropdownWidthSearch/DropdownWidthSearch";
import EcommarceIcon from "./EcommarceIcon/EcommarceIcon";
import "./header.scss";
import { useGetLogedUserQuery } from "../../redux/Service/api";
import { useEffect } from "react";
import { reduxLogin, saveUserData } from "../../redux/Slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
const Header = () => {
  const { isAuthenticated, userData } = useSelector((st: any) => st.auth);
  const dispatch = useDispatch();
  const {
    data,
    isError: getUserError,
    isLoading,
    refetch,
  } = useGetLogedUserQuery(undefined);
  console.log(data);

  useEffect(() => {
    if (data && !getUserError && localStorage.getItem("knoozToken")) {
      dispatch(reduxLogin());
      dispatch(saveUserData(data?.data));
    }
  }, [data, getUserError, dispatch]);
  return (
    <div className="flex items-center justify-between flex-wrap  pt-[20px] pb-[10px]">
      <Link
        to={"/"}
        className="min-w-[210px] maxlm:w-[160px] maxlm:min-w-[160px]   w-[210px] miillg:w-[300px] miillg:min-w-[300px]"
      >
        {/* <img className={`w-full h-full`} src={Logo} alt="" /> */}
        <img className="h-16 w-32" src={Logo} alt="" />
      </Link>
      <AllcatogeryDropdown />
      <DropdownWidthSearch />

      {isAuthenticated ? (
        <div className="flex flex-col pl-[40px] text-[#009E97] text-[18px] font-bold miillg:hidden">
          <span>{userData?.phone}</span>

          <span>{userData?.name}</span>
        </div>
      ) : (
        <div className="flex flex-col pl-[40px] text-[#009E97] text-[18px] font-bold miillg:hidden justify-center items-center">
          <span>+966554223001</span>
          <span>Guest</span>
        </div>
      )}

      <EcommarceIcon isAuthenticated={isAuthenticated} />
    </div>
  );
};

export default Header;
